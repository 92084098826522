import loadable from "@loadable/component";
import AEMWrapper from "@RHCommerceDev/aem/ComponentWrapper";
import ContainerWrapper from "concierge-alias-aem/ContainerWrapper";
import React from "react";
import meta from "./aem-meta";

const Component = loadable(
  () =>
    import(
      /* webpackChunkName: "container-animation-scroll-trigger/aem-component" */ "./aem-component"
    ),
  {
    fallback: <></>
  }
);

export default AEMWrapper(meta, Component, {
  wrapper: ContainerWrapper
});
