import React, { FC, useCallback } from "react";
import { Toolbar } from "@RHCommerceDev/utils/material-ui-core";
import { DrawerProps } from "@material-ui/core/Drawer";
import useAppData from "@RHCommerceDev/hooks/useAppData";
import SearchField from "@RHCommerceDev/container-search-field";
import memoize from "@RHCommerceDev/utils/memoize";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import {
  Theme,
  makeStyles,
  createStyles
} from "@RHCommerceDev/utils/material-ui-core";
import Drawer from "@RHCommerceDev/component-drawer";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";
import yn from "yn";

export interface SearchDrawerProps {
  onClose: () => void;
}

export const SearchDrawer: FC<SearchDrawerProps> = props => {
  /*  It looks to me like there is a bug in the Toolbar code from MUI
   *  When the styles are applied, MUIToolbar-gutters should be setting
   *  horizontal padding to 16px, but because it comes after MUIToolbar-regular
   *  in the list of classes applied, it is not, so we must do so ourselves
   */
  return (
    <Toolbar disableGutters style={{ paddingLeft: 16, paddingRight: 16 }}>
      <SearchField onClose={props.onClose} />
    </Toolbar>
  );
};

const SD = memoize(SearchDrawer);

SearchDrawer.defaultProps = {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      top: 0
    }
  })
);

export default memoize(({ onClose, open, ...rest }: DrawerProps) => {
  const env = useEnv();
  const { app, setApp } = useAppData();
  const localClasses = useStyles();
  const drawerCloseCb = useCallback(() => {
    setApp({ ...app, openSearchDrawer: false });

    if (!!onClose) {
      onClose({}, "backdropClick");
    }
  }, [onClose, setApp]);

  const sdCloseCb = useCallback(() => {
    setApp({ ...app, openSearchDrawer: false });
    if (!!onClose) {
      onClose({}, "backdropClick");
    }
  }, [app, onClose, setApp]);

  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;

  return (
    <DrawerComponent
      BackdropProps={{
        className: localClasses.backdrop
      }}
      anchor="top"
      elevation={0}
      onClose={drawerCloseCb}
      open={app?.openSearchDrawer || open}
      {...rest}
    >
      <div
        style={{
          padding: "24px 32px 18px 32px"
        }}
      >
        <SearchField
          onClose={drawerCloseCb}
          isRHRSearchField={true}
          isMobileView={true}
        />
      </div>
    </DrawerComponent>
  );
});
