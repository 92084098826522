import { PropTypes } from "@RHCommerceDev/aem/ComponentWrapper";

export default {
  title: "Guest House Nav",
  type: "rh/components/content/gh-nav",
  config: {
    emptyLabel: "Guest House Nav",
    isEmpty: ({}: PropTypes) => true
  }
};
