import React, { FC } from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import RHTextField from "@RHCommerceDev/component-rh-text-field";
import { TextFieldProps } from "@material-ui/core/TextField";

import {
  InputAdornment,
  createStyles,
  makeStyles,
  Theme
} from "@RHCommerceDev/utils/material-ui-core";
import RHSearchIcon from "@RHCommerceDev/icon-search";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rhTextField: {
      "& .MuiInputBase-root": {
        color: "inherit",
        background: "inherit"
      },
      "&.MuiFormControl-marginNormal": {
        marginTop: 0,
        marginBottom: 0
      },
      "& .MuiInputBase-input": {
        background: "transparent",
        padding: "7px 10px 7px 0",
        fontSize: 11,
        textTransform: "none"
      },
      "& .MuiInputAdornment-root": {
        color: "inherit"
      },
      "& ::placeholder": {
        color: "inherit",
        opacity: 1,
        fontSize: 11,
        textTransform: "none"
      },
      ".lightTextFieldBox & .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0,0,0,0.4) !important"
      },
      ".darkTextFieldBox & .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(255,255,255,0.4) !important"
      }
    }
  })
);

const SearchTextField: FC<TextFieldProps> = ({ InputProps, ...rest }) => {
  const classes = useStyles();
  return (
    <RHTextField
      {...rest}
      className={classes.rhTextField}
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <RHSearchIcon style={{ fontSize: 12 }} />
          </InputAdornment>
        ),
        ...InputProps
      }}
    />
  );
};

export default memoize(SearchTextField);
