import classNames from "classnames";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useNavigationStylesByBrand } from "@RHCommerceDev/hooks/useTypographyStyles";
import React, { forwardRef, Ref } from "react";
import { Link, LinkProps } from "react-router-dom";
import memoize from "@RHCommerceDev/utils/memoize";
import yn from "yn";

interface LinkpropsExt extends LinkProps {
  noLink?: boolean;
  classes?: any;
}
export const NavigationLink = forwardRef(
  (props: LinkpropsExt, ref: Ref<HTMLAnchorElement>) => {
    const { noLink, target = "_self", ...linkProps } = props;
    const typographyClasses = useNavigationStylesByBrand({
      keys: ["desktopCatalogLink"]
    });
    const env = useEnv();
    const FEATURE_GLOBAL_COUNTRY_SELECTOR = yn(
      env.FEATURE_GLOBAL_COUNTRY_SELECTOR
    );
    const targetUrl = props?.to?.toString();

    if (noLink) {
      return (
        <div
          data-nolink={props.dangerouslySetInnerHTML}
          className={classNames([
            typographyClasses.desktopCatalogLink,
            props.classes
          ])}
          dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
          style={
            FEATURE_GLOBAL_COUNTRY_SELECTOR ? { lineHeight: "inherit" } : {}
          }
        >
          {props.children}
        </div>
      );
    }
    try {
      new URL(targetUrl);
      return (
        <a
          ref={ref}
          href={targetUrl}
          target={target}
          style={props.style}
          className={classNames([
            typographyClasses.desktopCatalogLink,
            props.classes
          ])}
        >
          {props.children}
        </a>
      );
    } catch {
      return (
        <Link
          ref={ref}
          {...linkProps}
          target={target}
          className={classNames([
            typographyClasses.desktopCatalogLink,
            props.classes
          ])}
        >
          {props.children}
        </Link>
      );
    }
  }
);

export default memoize(NavigationLink);
