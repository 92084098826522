import React, { FC, useState, useCallback, useEffect } from "react";
import {
  Paper,
  useTheme,
  Grid,
  Popper,
  Fade,
  Divider
} from "@RHCommerceDev/utils/material-ui-core";
import MenuPopperContent from "./MenuPopperContent";
import analyticsLoader from "@RHCommerceDev/analytics/loader";
import { CatalogNavigationProps } from "./types";
import NavigationLink from "@RHCommerceDev/container-inline-header/NavigationLink";
import { isTouchDevice } from "@RHCommerceDev/utils/checkTouchDevice";
import memoize from "@RHCommerceDev/utils/memoize";
import EventEmitter from "@RHCommerceDev/utils-event-emitter";
import useParams from "@RHCommerceDev/hooks/useParams";
import RHSearchIcon from "@RHCommerceDev/icon-search";
import RHRSearchDrawer from "@RHCommerceDev/container-inline-header/RHRSearchDrawer";
import useBrand from "@RHCommerceDev/hooks-use-brand/useBrand";
import { useRhUserAtomValue } from "@RHCommerceDev/hooks/atoms";

// TODO: Header needs to be refactored entirely.
// Much of this can be done with pure CSS and HTML.
// This is a workaround for lack of Safari & Legacy
// React support for Touch and Pointer events.
if (isTouchDevice) {
  document.body.addEventListener("click", (e: Event) => {
    if (
      !(e.target as HTMLElement).matches(
        `#${L_NAV_ID},#${L_NAV_ID} *,#${D_POPOVER_ID},#${D_POPOVER_ID} *`
      )
    ) {
      EventEmitter.dispatch("header-blur", true);
    }
  });
}
const L_NAV_ID = "lower-nav";
const D_POPOVER_ID = "desktop-navigation-popover";

export const CatalogNavigation: FC<CatalogNavigationProps> = ({
  catalogNavigation,
  hoverStyle = "underline",
  menuDarkTheme,
  darkTheme,
  miniNav,
  isRhrCatalogNavigation,
  styles,
  Wrapper
}) => {
  const theme = useTheme();
  const [activeMenu, setActiveMenu] = useState<Maybe<NavigationElement>>(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [pendingActiveMenu, setPendingActiveMenu] =
    useState<Maybe<NavigationElement>>(null);
  const [anchorEl, setAnchorEl] = useState<Maybe<Element>>(null);
  const [openTimeout, setOpenTimeout] = useState<number | null>(null);
  const params = useParams({
    topCatId: ""
  });
  const { userType } = useRhUserAtomValue();
  const brand = useBrand();
  // some catalog links may not have hover states with menu.
  const catalogLinksToEnable = [
    "/catalog/sale/index.jsp",
    "/my-account/wish-list.jsp",
    "/content/category.jsp?context=BC_Registry",
    "/content/category.jsp?context=InteriorDesignServices",
    "/gift-registry"
  ];

  const clearOpenTimeout = useCallback(() => {
    if (openTimeout) {
      clearTimeout(openTimeout);
      setOpenTimeout(null);
      setPendingActiveMenu(null);
    }
  }, [openTimeout]);

  const doPopoverOpen = useCallback(
    (navElement: NavigationElement) => {
      if (!openTimeout) {
        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
            hoverStyle === "highlight" && {
              saleElement: navElement
            }
          )
        );
      }
      setActiveMenu(navElement);
      setPendingActiveMenu(null);
    },
    [openTimeout, hoverStyle]
  );

  const onMenuDelayOpen = useCallback(
    (navElement: NavigationElement) => {
      return (event: React.MouseEvent) => {
        if (activeMenu !== navElement) {
          if (openTimeout) {
            clearTimeout(openTimeout);
          }
          setAnchorEl(event.currentTarget);
          setActiveMenu(null);
          setPendingActiveMenu(navElement);
          if (!navElement.childCategories) {
            return; // no children, nothing to display in popup sub-menu
          }
          setOpenTimeout(
            window.setTimeout(
              () => {
                doPopoverOpen(navElement);
              },
              isTouchDevice ? 0 : 200
            )
          );
        }
      };
    },
    [openTimeout, activeMenu, doPopoverOpen]
  );

  const onPopoverClose = useCallback(
    (event?: React.MouseEvent) => {
      if (event?.type !== "click") {
        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
            { type: "desk" }
          )
        );
      }
      setTimeout(() => {
        setAnchorEl(null);
      }, 0); // setAnchorEl must be in timeout to work properly
      clearOpenTimeout();
    },
    [clearOpenTimeout]
  );

  const onMenuClose = useCallback(
    (navElement: NavigationElement) => {
      return () => {
        if (activeMenu === navElement) {
          onPopoverClose();
        } else {
          clearOpenTimeout();
        }
        setActiveMenu(null);
        setPendingActiveMenu(null);
      };
    },
    [clearOpenTimeout, onPopoverClose, activeMenu]
  );

  const blurHandler = useCallback(() => {
    onMenuClose(activeMenu as NavigationElement)();
  }, [onMenuClose, activeMenu]);

  const showDivider = navElement => {
    if (params.topCatId === navElement.id) {
      return true;
    }
    return (
      hoverStyle === "underline" &&
      (activeMenu === navElement || pendingActiveMenu === navElement) &&
      !!anchorEl
    );
  };

  useEffect(() => {
    if (isTouchDevice) {
      EventEmitter.subscribe(`header-blur`, blurHandler);
      return () => {
        EventEmitter.remove(`header-blur`, blurHandler);
      };
    }
  }, [blurHandler]);

  return (
    <Grid
      id={L_NAV_ID}
      container
      justify="space-evenly"
      style={{
        ...styles?.root
      }}
    >
      {catalogNavigation?.childCategories?.map((navElement, i) => {
        let category;
        if (navElement?.targetUrl?.indexOf(".jsp") === -1) {
          category = navElement?.targetUrl;
        } else {
          category = navElement?.targetUrl?.substring(
            0,
            navElement?.targetUrl?.indexOf(".jsp") + 4
          );
          if (category === "/content/category.jsp") {
            category = navElement.targetUrl;
          }
        }
        const isExternalLink =
          navElement?.targetUrl?.trim().indexOf("http") === 0;
        const enabledLink =
          catalogLinksToEnable.includes(category) || isExternalLink;
        const events = {
          [isTouchDevice ? "onPointerDown" : "onMouseEnter"]:
            onMenuDelayOpen(navElement),
          ...(isTouchDevice ? {} : { onMouseLeave: onMenuClose(navElement) })
        };

        return !(
          navElement?.targetUrl?.includes("gift-registry") &&
          (brand === "TN" || userType === "CONTRACT" || userType === "TRADE")
        ) ? (
          <Grid
            item
            id={`catalogNav-${navElement.id}`}
            key={`catalogNav-${navElement.id}`}
            data-navigation-account-item-id={
              navElement.targetUrl === "/my-account/wish-list.jsp"
                ? "wish-list"
                : navElement.id
            }
            {...events}
            style={{
              // Logic below is the overcomplicated JS eqivalent of :first-child/:last-child
              padding: `5px ${
                i + 1 === catalogNavigation?.childCategories?.length
                  ? "0px"
                  : "10px"
              } 0 ${i === 0 ? "0px" : "10px"}`,
              // Padding + negative marginTop allows for larger touch point
              marginTop: "-5px",
              height: hoverStyle === "underline" ? 25 : "auto",
              display: "flex"
            }}
          >
            <NavigationLink
              to={navElement.targetUrl}
              noLink={!enabledLink}
              target={isExternalLink ? "_blank" : "_self"}
              aria-owns={activeMenu === navElement ? D_POPOVER_ID : undefined}
              aria-haspopup={activeMenu === navElement ? "true" : "false"}
              dangerouslySetInnerHTML={
                Wrapper
                  ? undefined
                  : {
                      __html: navElement.displayName.replace(/<br>/g, "")
                    }
              }
            >
              {Wrapper && (
                <Wrapper
                  dangerouslySetInnerHTML={{
                    __html: navElement.displayName.replace(/<br>/g, "")
                  }}
                />
              )}
            </NavigationLink>
            {showDivider(navElement) && (
              <Divider
                style={{
                  marginBottom: 0,
                  marginTop: 5,
                  backgroundColor: menuDarkTheme
                    ? theme.palette.common.white
                    : darkTheme
                    ? theme.palette.common.white
                    : theme.palette.common.black
                }}
              />
            )}

            {/* Please note that MUI uses popper.js v1 NOT v2 */}
            {activeMenu === navElement && (
              <Popper
                id={D_POPOVER_ID}
                transition
                open={!!anchorEl}
                anchorEl={anchorEl}
                placement="bottom-start"
                modifiers={{
                  preventOverflow: {
                    mainAxis: true,
                    altAxis: false,
                    padding: 0
                  },
                  flip: {
                    enabled: false
                  },
                  offset: {
                    offset: "-40, 0"
                  }
                }}
                style={{
                  zIndex: theme.zIndex.tooltip
                }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      square
                      style={{
                        width: 500,
                        marginTop: "31px",
                        boxShadow:
                          "0px 1px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.07), 0px 1px 1px 0px rgba(0,0,0,0.06)"
                      }}
                    >
                      <MenuPopperContent
                        navigationElement={activeMenu}
                        onLinkClick={onPopoverClose}
                        hoverStyle={hoverStyle}
                        darkTheme={menuDarkTheme}
                      />
                    </Paper>
                  </Fade>
                )}
              </Popper>
            )}
          </Grid>
        ) : null;
      })}
      {miniNav && isRhrCatalogNavigation && (
        <Grid item>
          {" "}
          <RHSearchIcon
            style={{ fontSize: "17px", marginTop: -8, cursor: "pointer" }}
            onClick={() => {
              setSearchOpen(true);
            }}
          />
          <RHRSearchDrawer
            searchOpen={searchOpen}
            setSearchOpen={setSearchOpen}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default memoize(CatalogNavigation);
