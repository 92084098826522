import React, { FC } from "react";
import memoize from "utils/memoize";
import Drawer from "@RHCommerceDev/component-drawer";
import SearchField from "@RHCommerceDev/container-search-field";
import { RHRSearchDrawerProps } from "./types";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import yn from "yn";

const RHRSearchDrawer: FC<RHRSearchDrawerProps> = ({
  searchOpen,
  setSearchOpen,
  paperPropsStyle,
  searchBoxStyle
}) => {
  const env = useEnv();
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;
  return (
    <DrawerComponent
      id="desktop-search-popper"
      open={searchOpen}
      anchor={"right"}
      PaperProps={{
        style: {
          width: 380,
          ...paperPropsStyle
        }
      }}
      disableBackdropClick={false}
      disableScrollLock
      onClose={() => setSearchOpen(false)}
    >
      <div
        style={{
          paddingLeft: 40,
          paddingRight: 40,
          paddingBottom: 40,
          paddingTop: 24,
          ...searchBoxStyle
        }}
      >
        <SearchField
          onClose={() => {
            setSearchOpen(false);
          }}
          isRHRSearchField={true}
        />
      </div>
    </DrawerComponent>
  );
};

export default memoize(RHRSearchDrawer);
