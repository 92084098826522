import React, { FC, useRef, useState, useCallback } from "react";
import {
  Fade,
  Grid,
  IconButton,
  Link,
  Popover
} from "@RHCommerceDev/utils/material-ui-core";
import SearchField from "@RHCommerceDev/container-search-field";
import useAppData from "@RHCommerceDev/hooks/useAppData";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import RHSearchIcon from "@RHCommerceDev/icon-search";
import yn from "yn";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import buildPath from "@RHCommerceDev/utils/buildPath";
import AccountNavMenu from "@RHCommerceDev/container-inline-header/AccountNavMenu";
import { OtherNavigationProps } from "@RHCommerceDev/container-inline-header/types";
import { default as RouterLink } from "@RHCommerceDev/container-inline-header/NavigationLink";
import memoize from "@RHCommerceDev/utils/memoize";

type StyleProps = {
  link?: React.CSSProperties;
};

export const OtherNavigation: FC<OtherNavigationProps> = ({
  profileStatus,
  cartProjection,
  styles,
  menuDarkTheme
}) => {
  const { app } = useAppData();
  const env = useEnv();
  const prefix = useLocalization();
  const cartBrokerEnabled = yn(env.FEATURE_PDP_CART_BROKER);
  const [searchOpen, setSearchOpen] = useState(false);
  const quantity = cartBrokerEnabled
    ? cartProjection?.totalItemQuantity
    : profileStatus?.cartCount;
  const searchAnchorEl = useRef(null);
  const targetUrl = buildPath(`${prefix}/rooms.jsp`, {
    sale: app.saleContextFilter
  });
  const iconButtonOnClickCb = useCallback(event => {
    event.preventDefault();
    setSearchOpen(true);
  }, []);
  const closeSearchCb = useCallback(() => setSearchOpen(false), []);
  return (
    <>
      <Grid style={styles?.link?.root} item>
        <Link component={RouterLink} to={targetUrl} underline="none">
          SHOP ROOMS
        </Link>
      </Grid>
      <Grid style={styles?.link?.root} item>
        <IconButton
          disableRipple
          id="container-inlineHeader-otherNavigation_search-btn"
          ref={searchAnchorEl}
          color="inherit"
          onClick={iconButtonOnClickCb}
          style={{ padding: 0 }}
        >
          <RHSearchIcon style={{ fontSize: 17 }} />
        </IconButton>
        <Popover
          BackdropProps={{
            invisible: false,
            style: { backgroundColor: "rgba(0, 0, 0, 0.2)" }
          }}
          id="desktop-search-popper"
          open={searchOpen}
          anchorEl={searchAnchorEl.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          getContentAnchorEl={null}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          TransitionComponent={Fade}
          transitionDuration={350}
          PaperProps={{
            style: {
              width: 500,
              marginTop: 10,
              borderRadius: 0
            }
          }}
          disableBackdropClick={false}
          disableScrollLock
          onClose={closeSearchCb}
        >
          <div style={{ padding: "35px 65px" }}>
            <SearchField onClose={closeSearchCb} />
          </div>
        </Popover>
      </Grid>
      <AccountNavMenu
        style={styles?.link?.root}
        darkTheme={menuDarkTheme}
        profileStatus={profileStatus}
      />
      <Grid style={styles?.link?.root} item>
        <Link
          id="container-other-navigation_cart-btn"
          component={RouterLink}
          to={`${prefix}/checkout/shopping_cart.jsp`}
          underline="none"
        >
          CART {quantity || 0}
        </Link>
      </Grid>
    </>
  );
};

export default memoize(OtherNavigation);
