import { PropTypes } from "@RHCommerceDev/aem/ComponentWrapper";

export default {
  title: "Visual Site Map",
  type: "rh/components/content/visualsitemap",
  config: {
    emptyLabel: "Visual Site Map",
    isEmpty: ({}: PropTypes) => true
  }
};
