import { PropTypes, WrapperOptions } from "@RHCommerceDev/aem/ComponentWrapper";
import { Children } from "react";

const meta: WrapperOptions = {
  group: "Animation",
  title: "Animation Scroll Trigger",
  type: "rh/components/animation/scroll-trigger",
  isContainer: true,
  config: {
    emptyLabel: "No children",
    isEmpty: ({ children }: PropTypes) => Children.count(children) === 0
  }
};

export default meta;
