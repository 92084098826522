import React, { FC, useCallback } from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import { countries } from "@RHCommerceDev/resources/countries-config.json";

import { BREAKPOINT_MD } from "@RHCommerceDev/utils/constants";
import { useViewHeight } from "@RHCommerceDev/hooks/useViewHeight";
import { Slide } from "@material-ui/core";
import CountrySelectorDialog from "@RHCommerceDev/dialog-country-selector";
import useUserPreferences from "@RHCommerceDev/hooks/useUserPreferences";
import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";

interface MobileCountrySelectorProps {
  isVisible: boolean;
  onClose: () => void;
  activeShippingSelector?: string | null;
}

const MobileCountrySelector: FC<MobileCountrySelectorProps> = ({
  isVisible,
  onClose,
  activeShippingSelector
}) => {
  const viewHeight = useViewHeight();
  const postalCode = useIsoCookies(["pc"])?.pc;

  const {
    country,
    language,
    handleSaveCookies,
    setCountry,
    setLanguage,
    setMeasurement
  } = useUserPreferences();

  const handleSubmit = useCallback(
    (country: string, language: string, shouldRedirect = true) => {
      const countryValue = countries?.[country]?.defaultValues;
      setCountry(country);
      setLanguage(language);
      setMeasurement(countryValue?.measurement);
      document?.body?.dispatchEvent(
        new CustomEvent("cta_click", {
          detail: {
            item: {
              isCountrySwitch: true,
              fromFooter: true,
              country: countryValue.country
            }
          }
        })
      );

      // NOTE: Don't remove postal code as we don't
      // want to override previously set postcode inside handleSaveCookies
      handleSaveCookies(
        { country, language, postalCode: !shouldRedirect ? postalCode : null },
        undefined,
        undefined,
        shouldRedirect
      );
    },
    [handleSaveCookies, setCountry, setLanguage, setMeasurement, postalCode]
  );

  return (
    <Slide direction="left" in={isVisible} mountOnEnter unmountOnExit>
      <div
        className={"bg-transparent w-75"}
        style={{ height: `${100 * viewHeight}px` }}
      >
        <div
          className={
            "flex flex-col items-center gap-10 sm:w-full sm:items-start"
          }
        >
          <CountrySelectorDialog
            onClose={onClose}
            country={country}
            language={language}
            handleSubmit={handleSubmit}
            className={{ dialogTitleFont: "!FONT_BARON_SANS_LIGHT_BASICS" }}
            breakPoint={BREAKPOINT_MD}
          />
        </div>
      </div>
    </Slide>
  );
};

export default memoize(MobileCountrySelector);
